import Vue from 'vue';
import Vuex from 'vuex';
import * as VIEW_MODES from '../constants/viewModes';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // вообще напрашивается роутер, НО я не уверен что подружится с состоянием сцены без проблем.
    activeViewMode: VIEW_MODES.PREVIEW,
    // активный на сцене ТИП цветка (который выделен)
    activeFlowerTypeId: undefined,
    activeFlowerCutFactor: 0,
    // ТИПЫ цветков добавленных на сцену (дублируются)
    addedFlowersTypesIds: [],
    flowersTypes: [],
    backgrounds: [],
    pots: [],
    addFlowerViewTag: undefined,
    isSceneLoaded: false
  },
  getters: {
    checkViewModeIsActive(state) {
      return viewMode => state.activeViewMode === viewMode;
    },

    flowersTypesByActiveTag(state) {
      if (!state.addFlowerViewTag) {
        return state.flowersTypes;
      }

      return state.flowersTypes.filter(type => type.tags.find(tag => state.addFlowerViewTag.includes(tag)));
    },

    orderFlowersData(state) {
      return Object.values(state.addedFlowersTypesIds
        .map(typeId => state.flowersTypes.find(type => type.id === typeId))
        .reduce((types, type) => {
          types[type.id] = types[type.id] || { type, count: 0, totalPrice: 0 };
          types[type.id].count += 1;
          types[type.id].totalPrice += type.price;

          return types;
        }, {}));
    },

    orderTotalPrice(state, getters) {
      return getters.orderFlowersData.reduce((total, flowerData) => total + flowerData.totalPrice, 0)
    }
  },
  mutations: {
    setActiveViewMode(state, mode) {
      window.puzzlesBus.setEditMode(mode === VIEW_MODES.EDIT);
      state.activeViewMode = mode;
    },

    setSceneLoadedFlag(state, value) {
      state.isSceneLoaded = value;
    },

    setActiveFlowerTypeId(state, id) {
      state.activeFlowerTypeId = id;
    },

    setAddedFlowersTypesIds(state, flowers) {
      state.addedFlowersTypesIds = [...flowers];
    },

    setAddFlowerViewTag(state, tag) {
      state.addFlowerViewTag = tag;
    },

    setActiveFlowerCutFactor(state, factor) {
      state.activeFlowerCutFactor = factor;
    },

    setFlowersTypes(state, data) {
      state.flowersTypes = data;
    },

    setBackgrounds(state, data) {
      state.backgrounds = data;
    },

    setPots(state, data) {
      state.pots = data;
    },
  },
  actions: {
    fetchFlowersTypes({ commit }) {
      return fetch('/flowers_types.json')
        .then(response => response.json())
        .then(data => commit('setFlowersTypes', data));
    },

    fetchBackgrounds({ commit }) {
      return fetch('/backgrounds.json')
        .then(response => response.json())
        .then(data => commit('setBackgrounds', data));
    },

    fetchPots({ commit }) {
      return fetch('/pots.json')
        .then(response => response.json())
        .then(data => commit('setPots', data));
    },

    callSceneUndo({ commit }) {
      return window.puzzlesBus.undo().then(([selectedIds, activeId]) => {
        commit('setAddedFlowersTypesIds', [...selectedIds]);
        commit('setActiveFlowerTypeId', activeId);
      });
    },

    callSceneRedo({ commit }) {
      return window.puzzlesBus.redo().then(([selectedIds, activeId]) => {
        commit('setAddedFlowersTypesIds', [...selectedIds]);
        commit('setActiveFlowerTypeId', activeId);
      });
    },

    callSceneCloneActiveFlower({ commit }) {
      return window.puzzlesBus.cloneFlower().then(([selectedIds, activeId]) => {
        commit('setAddedFlowersTypesIds', [...selectedIds]);
        commit('setActiveFlowerTypeId', activeId);
      });
    },

    callSceneDeleteActiveFlower({ commit }) {
      return window.puzzlesBus.deleteFlower().then(([selectedIds, activeId]) => {
        commit('setAddedFlowersTypesIds', [...selectedIds]);
        commit('setActiveFlowerTypeId', activeId);
      });
    },

    callSceneAddFlower({ state, commit }, flowerTypeId) {
      return window.puzzlesBus.loadFlower(flowerTypeId).then(() => {
        commit('setAddedFlowersTypesIds', [...state.addedFlowersTypesIds, flowerTypeId]);
        commit('setActiveFlowerTypeId', flowerTypeId);
      });
    },

    callDeleteById({ commit }, id) {
      return window.puzzlesBus.deleteById(id).then(([selectedIds, activeId]) => {
        commit('setAddedFlowersTypesIds', [...selectedIds]);
        commit('setActiveFlowerTypeId', activeId);
      });
    },

    callChangeFlower({ commit }, id) {
      return window.puzzlesBus.changeFlower(id).then(([selectedIds, activeId]) => {
        commit('setAddedFlowersTypesIds', [...selectedIds]);
        commit('setActiveFlowerTypeId', activeId);
      });
    },

    callChangeFlowersById({ commit }, id) {
      return window.puzzlesBus.changeFlowersById(id).then(([selectedIds, activeId]) => {
        commit('setAddedFlowersTypesIds', [...selectedIds]);
        commit('setActiveFlowerTypeId', activeId);
      });
    },

    callSetFlowerCutFactor({ commit }, factor) {
      commit('setActiveFlowerCutFactor', factor); // сразу устанавливаем, надеемся что не разойдется
      return window.puzzlesBus.cutFlower(factor / 100);
    },

    callGetFlowerCutFactor({ commit }) {
      return window.puzzlesBus.getCutFactor().then(factor => commit('setActiveFlowerCutFactor', factor * 100));
    },

    callSetBackground(context, index) {
      return window.puzzlesBus.setBackground(index);
    },

    callSetPot(context, index) {
      return window.puzzlesBus.setPot(index);
    },
  },
});
