<template>
  <div class="app-change-pot-view">
    <app-back-to-button @click="setActiveViewMode(VIEW_MODES.PREVIEW)" />
    <app-slider
      class="app-change-pot-view__slider"
      :items="sliderItems"
      @select="item => callSetPot(item.id)"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import AppBackToButton from '@/components/BackToButton';
import * as VIEW_MODES from '../constants/viewModes';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import AppSlider from '@/components/Slider';
import numberToPrice from '@/helpers/numberToPrice';

export default {
  name: 'AppChangePotView',
  components: {
    AppSlider,
    AppBackToButton,
    Swiper,
    SwiperSlide,
  },
  data: () => ({ VIEW_MODES }),
  computed: {
    ...mapState(['pots']),

    sliderItems() {
      return this.pots.map(pots => ({
        id: pots.id,
        previewImage: pots.preview_path,
        title: pots.name,
        subtitle: numberToPrice(pots.price),
      }));
    },
  },
  methods: {
    ...mapMutations(['setActiveViewMode']),

    ...mapActions(['callSetPot']),
  },
};
</script>

<style lang="scss">
.app-change-pot-view {
  &__slider {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
</style>
