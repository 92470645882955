<template>
  <div class="app-cut-view">
    <app-back-to-button @click="handleBackClick" />
    <el-slider
      class="app-cut-view__slider"
      vertical
      height="258px"
      :value="activeFlowerCutFactor"
      :show-tooltip="false"
      @input="callSetFlowerCutFactor"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import AppButtonImage from '@/components/ButtonImage';
import AppBackToButton from '@/components/BackToButton';
import * as VIEW_MODES from '../constants/viewModes';

export default {
  name: 'AppCutView',
  components: { AppBackToButton, AppButtonImage },
  data: () => ({
    activeGearMenuId: undefined,
    modes: [
      'preview',
      'edit',
    ],
    VIEW_MODES
  }),
  mounted() {
    this.callGetFlowerCutFactor();
  },
  computed: {
    ...mapState(['activeFlowerCutFactor']),
  },
  methods: {
    ...mapMutations(['setActiveViewMode']),

    ...mapActions([
      'callSetFlowerCutFactor',
      'callGetFlowerCutFactor',
    ]),

    handleBackClick() {
      this.$puzzlesBus.saveToLink();
      this.setActiveViewMode(VIEW_MODES.EDIT);
    }
  },
};
</script>

<style lang="scss">
.app-cut-view {

  &__slider {
    position: absolute;
    top: calc(50% - 129px);
    right: 82px;
    z-index: 20;
  }

  // перестилизуем слайдер element
  .el-slider.is-vertical {
    position: absolute;
  }

  .el-slider.is-vertical .el-slider {
    &__bar {
      background-color: transparent;
    }

    &__runway {
      background-color: #cfcfcf;
      margin: 0;
      width: 8px;
      border-radius: 5px;
    }

    &__button-wrapper {
      width: 45px;
      height: 9px;
      left: -28px;
      padding: 10px;
      box-sizing: content-box;
    }

    &__button {
      width: 45px;
      height: 9px;
      border-radius: 5px;
      background-color: #2dc16f;
      border: none;
      display: block;

    }
  }
}
</style>
