<template>
  <app-modal class="app-add-flower-view">
    <app-back-to-button @click="setActiveViewMode(VIEW_MODES.EDIT)" />
    <div class="app-add-flower-view__tags">
      <div
        v-for="(tag, tagIndex) in tags"
        :key="tagIndex"
        class="app-add-flower-view__tag"
        :class="{'app-add-flower-view__tag--active': addFlowerViewTag === tag.value}"
        @click="() => setAddFlowerViewTag(tag.value)"
      >
        {{ tag.name }}
      </div>
    </div>
    <div class="app-add-flower-view__flowers">
      <div class="app-add-flower-view__flowers-wrapper">
        <vue-scroll
          ref="scroll"
          :ops="scrollOptions"
        >
          <app-flower-row-item
            v-for="flowerType in flowersTypesByActiveTag"
            :key="flowerType.id"
            class="app-add-flower-view__flower-row-item"
            :name="flowerType.name"
            :price="flowerType.price"
            :preview-image-path="flowerType.preview_path"
          >
            <app-button-image
              width="30px"
              height="31px"
              icon-path="/assets/check.svg"
              @click="() => _onClickAddFlower(flowerType)"
            />
          </app-flower-row-item>
        </vue-scroll>
      </div>
    </div>
  </app-modal>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import AppFlowerRowItem from '@/components/FlowerRowItem';
import AppButtonImage from '@/components/ButtonImage';
import AppModal from '@/components/Modal';
import AppBackToButton from '@/components/BackToButton';
import * as VIEW_MODES from '../constants/viewModes';

const MODES = {
  NEW: 'new',
  REPLACE_SINGLE: 'replace_single',
  REPLACE_ALL: 'replace_all',
};

export default {
  name: 'AppAddFlowerView',
  components: { AppBackToButton, AppModal, AppButtonImage, AppFlowerRowItem },
  props: {
    // наверное (по хорошему), нужно разделять на разные компоненты
    mode: {
      type: String,
      default: 'new',
      validator: value => Object.values(MODES).includes(value),
    },
  },
  data: () => ({
    tags: [
      { name: '전체', value: undefined },
      { name: 'Form', value: 'form' },
      { name: 'Mass', value: 'mass' },
      { name: 'Line', value: 'line' },
    ],
    scrollOptions: {
      vuescroll: {
        sizeStrategy: 'number',
      },
      bar: {
        keepShow: true,
        background: '#cacaca',
        size: '14px',
      },
      rail: {
        background: '#fff',
        opacity: 1,
        size: '14px',
      },
    },
    VIEW_MODES,
  }),
  mounted() {
    window.addEventListener('resize', this.updateScroll);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateScroll);
  },
  computed: {
    ...mapState([
      'addFlowerViewTag',
    ]),

    ...mapGetters([
      'flowersTypesByActiveTag',
    ]),
  },
  methods: {
    ...mapMutations([
      'setAddFlowerViewTag',
      'setActiveViewMode',
    ]),

    ...mapActions([
      'callSceneAddFlower',
      'callChangeFlower',
      'callChangeFlowersById',
    ]),

    updateScroll() {
      this.$nextTick(() => this.$refs.scroll && this.$refs.scroll.refresh());
    },

    _onClickAddFlower(flowerType) {
      if (this.mode === MODES.NEW) {
        this.callSceneAddFlower(flowerType.id);
      } else if (this.mode === MODES.REPLACE_SINGLE) {
        this.callChangeFlower(flowerType.id);
      } else if (this.mode === MODES.REPLACE_ALL) {
        this.callChangeFlowersById(flowerType.id);
      }

      this.setActiveViewMode(VIEW_MODES.EDIT);
    },
  },
};
</script>

<style lang="scss">
.app-add-flower-view {
  &__tags {
    display: flex;
    justify-content: flex-start;
    flex: 0 0 auto;
    padding: 48px 14px 0 39px;
    box-sizing: border-box;
  }

  &__tag {
    height: 26px;
    box-sizing: border-box;
    border-radius: 13px;
    font-size: 15px;
    letter-spacing: 0.45px;
    background-color: #b5b5b5;
    margin-right: 5px;
    color: #fff;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    cursor: pointer;
    margin-bottom: 15px;

    &--active {
      cursor: default;
      background-color: #ff3e3e;
    }
  }

  &__flowers {
    height: 100%;
    overflow: hidden;
    padding: 0 14px 0 39px;
    box-sizing: border-box;
  }

  &__flowers-wrapper {
    height: 100%;
  }

  &__flower-row-item {
    margin-bottom: 10px;
    width: 269px;
  }
}
</style>
