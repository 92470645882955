<template>
  <div class="app-modal">
    <div class="app-modal__wrapper">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppModal',
  components: {},
  props: {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss">
.app-modal {
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &__wrapper {
    background-color: #f1f1f1;
    height: 100%;
    width: 360px;
    display: flex;
    flex-direction: column;
    z-index: 20;
    position: relative;
  }
}
</style>
