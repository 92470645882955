<template>
  <div id="app">
    <app-main-view />
  </div>
</template>

<script>
import AppMainView from '@/views/MainView';

export default {
  name: 'App',
  components: {
    AppMainView,
  },
};
</script>

<style>
#app {
  height: 100%;
  width: 100%;
}

html,
body {
  height: 100%;
  width: 100%;
}
</style>
