export const PREVIEW = 'preview';

export const EDIT = 'edit';

export const CUT = 'cut';

export const ADD_FLOWER = 'add_flower';

export const REPLACE_FLOWER_SINGLE = 'replace_flower_single';

export const REPLACE_FLOWER_ALL = 'replace_flower_all';

export const REPLACE_FLOWER_QUESTION = 'replace_flower_question';

export const CHANGE_BACKGROUND = 'change_background';

export const CHANGE_POT = 'change_pot';

export const ORDER = 'order';

export const ORDER_CONFIRM_VIEW = 'order_confirm_view'
