//this._bus.trigger('ui-change', [
//  {name: '_global_____', value: 'aaa'},
//]);

class PuzzlesBus {
  constructor({ bus, store }) {
    this._bus = bus;
    this._store = store;

    this._bus.on('puzzles-trigger-load-scene-end', this.onSceneLoaded.bind(this));
  }

  // сцена уже полностью инициализировась
  init() {
    this._bus.on('store-trigger', this._storeHandler.bind(this));
    this._bus.on('puzzles-trigger', this._puzzlesHandler.bind(this));

    this._bus.on('puzzles-trigger-select-object', this.onSelectObject.bind(this));
    this._bus.on('puzzles-trigger-load-item-start', this.onLoadItemStart.bind(this));
    this._bus.on('puzzles-trigger-load-item-stop', this.onLoadItemEnd.bind(this));
  }

  _storeHandler(event) {
    const method = 'onStore' + (event.action.charAt(0).toUpperCase() + event.action.slice(1));
    this[method](event);
  }

  _puzzlesHandler(event) {
    const method = 'onPuzzles' + (event.action.charAt(0).toUpperCase() + event.action.slice(1));
    this[method](event);
  }

  _convertSceneObjectsToIds(objects) {
    return objects.map(name => this._convertSceneObjectToId(name));
  }

  _convertSceneObjectToId(objectName) {
    return Number(objectName.split('_')[0].replace('flower', ''));
  }

  // Когда в сцене выбрали цветок
  onSelectObject([activeId]) {
    this._store.commit('setActiveFlowerTypeId', this._convertSceneObjectToId(activeId));
    this.getCutFactor().then(factor => this._store.commit('setActiveFlowerCutFactor', factor * 100));
  }

  // Сцена востановила состояние из ссылки
  onSceneLoaded([objects, activeObjectId]) {
    const ids = this._convertSceneObjectsToIds(objects.slice());
    this._store.commit('setAddedFlowersTypesIds', ids);
    if (activeObjectId) {
      this._store.commit('setActiveFlowerTypeId', this._convertSceneObjectToId(activeObjectId));
    }
  }

  // Когда в сцене что-то грузится
  onLoadItemStart() {
    this._store.commit('setSceneLoadedFlag', true);
  }

  // Когда в сцене что-то закончило грузится
  onLoadItemEnd() {
    this._store.commit('setSceneLoadedFlag', false);
  }


  // Если выставлено в true то сцена считает что мы в режиме редактирования
  setEditMode(value) {
    this._bus.trigger('ui-change', [
        {name: '_EDIT_MODE', value: value},
    ]);
  }

  // Добавляет на сцену новый цветок
  loadFlower(id) {
    return new Promise((resolve) => {
      this._bus.once('puzzles-trigger-load-flower-end', ([puzzlesId]) => {
        resolve(Number(puzzlesId));
      });
      this._bus.trigger('ui-call-proc', { name: 'load_flower', args: [id] });
    });
  }

  // Клонирование цветка
  cloneFlower() {
    return new Promise((resolve) => {
      this._bus.once('puzzles-trigger-clone-flower-end', ([objects, activeObjectId]) => {
        resolve([
          this._convertSceneObjectsToIds(objects.slice()),
          this._convertSceneObjectToId(activeObjectId)
          ]);
      });
      this._bus.trigger('ui-call-proc', { name: 'clone_flower' });
    });
  }

  // Удаление выбранного цветка
  deleteFlower() {
    return new Promise((resolve) => {
      this._bus.once('puzzles-trigger-delete-flower-end', ([objects, activeObjectId]) => {
        resolve([this._convertSceneObjectsToIds(objects.slice()), this._convertSceneObjectToId(activeObjectId)]);
      });
      this._bus.trigger('ui-call-proc', { name: 'delete_flower' });
    });
  }

  // Удаление всех с одним id
  deleteById(id) {
    return new Promise((resolve) => {
      this._bus.once('puzzles-trigger-delete-by-type-end', ([objects, activeObjectId]) => {
        resolve([this._convertSceneObjectsToIds(objects.slice()), this._convertSceneObjectToId(activeObjectId)]);
      });
      this._bus.trigger('ui-call-proc', { name: 'delete_by_type', args: [id] });
    });
  }

  // Замена одного (активного) цветка
  changeFlower(id) {
    return new Promise((resolve) => {
      this._bus.once('puzzles-trigger-change-flower-end', ([objects, activeObjectId]) => {
        resolve([this._convertSceneObjectsToIds(objects.slice()), this._convertSceneObjectToId(activeObjectId)]);
      });
      this._bus.trigger('ui-call-proc', { name: 'change_flower', args: [`flower${id}`] });
    });
  }

  // Замена всех цветков по id веделенного цветка на другой тип
  changeFlowersById(id) {
    return new Promise((resolve) => {
      this._bus.once('puzzles-trigger-change-by-type', ([objects, activeObjectId]) => {
        resolve([this._convertSceneObjectsToIds(objects.slice()), this._convertSceneObjectToId(activeObjectId)]);
      });
      this._bus.trigger('ui-call-proc', { name: 'change_by_type', args: [`flower${id}`] });
    });
  }

  // Обрезка цветка
  cutFlower(factor) {
    this._bus.trigger('ui-call-proc', { name: 'cut_flower', args: [factor] });
  }

  // Получить значение обрезки выделенного цветка
  getCutFactor() {
    return new Promise((resolve) => {
      this._bus.once('puzzles-trigger-get-cut-factor-end', ([factor]) => {
        resolve(factor);
      });
      this._bus.trigger('ui-call-proc', { name: 'get_cut_factor' });
    });
  }

  // Грузит новый горшок по индексу 1-8
  setPot(index) {
    this._bus.trigger('ui-call-proc', { name: 'set_pot', args: [index] });
  }

  // Грузит новый бекграунд по индексу 1-8
  setBackground(index) {
    this._bus.trigger('ui-call-proc', { name: 'set_bg', args: [index] });
  }

  // Сохраняет состояние в ссылку
  saveToLink() {
    this._bus.trigger('ui-call-proc', { name: 'link'});
  }

  initAR() {
    this._bus.trigger('ui-call-proc', { name: 'init_ar'});
  }

  // Undo
  undo() {
    return new Promise((resolve) => {
      this._bus.once('puzzles-trigger-undo-end', ([objects, activeObjectId]) => {
        resolve([this._convertSceneObjectsToIds(objects.slice()), this._convertSceneObjectToId(activeObjectId)]);
      });
      this._bus.trigger('ui-call-proc', { name: 'undo' });
    });
  }

  // Redo
  redo() {
    return new Promise((resolve) => {
      this._bus.once('puzzles-trigger-redo-end', ([objects, activeObjectId]) => {
        resolve([this._convertSceneObjectsToIds(objects.slice()), this._convertSceneObjectToId(activeObjectId)]);
      });
      this._bus.trigger('ui-call-proc', { name: 'redo' });
    });
  }

  //
  unActive() {
    this._bus.trigger('ui-call-proc', { name: 'unactive'});
  }

}

export default PuzzlesBus;
