<template>
  <app-button-image
    class="app-back-to-button"
    width="26px"
    height="18px"
    icon-path="/assets/back_b.svg"
    @click="$emit('click')"
  />
</template>

<script>
import AppButtonImage from '@/components/ButtonImage';

export default {
  name: 'AppBackToButton',
  components: { AppButtonImage },
};
</script>

<style lang="scss">
.app-back-to-button {
  position: absolute;
  top: 20px;
  left: 13px;
  z-index: 20;
}
</style>
