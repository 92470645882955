<template>
  <div
    class="app-gear-menu"
  >
    <app-button-image
      class="app-gear-menu__button"
      :active="opened"
      icon-path="/assets/gear_menu/gear.svg"
      active-icon-path="/assets/gear_menu/gear_active.svg"
      width="30px"
      height="30px"
      @click="opened = !opened"
    />
    <transition name="fade">
      <div
        class="app-gear-menu__items"
        v-show="opened"
      >
        <div
          v-for="item in items"
          :key="item.id"
          class="app-gear-menu__item"
          @click="() => _onMenuItemClick(item)"
        >
          <div class="app-gear-menu__item-title">{{ item.title }}</div>
          <app-button-image
            width="21px"
            height="21px"
            :active-icon-path="item.activeIconPath"
            :icon-path="item.iconPath"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import AppButtonImage from '@/components/ButtonImage';
import * as VIEW_MODES from '../constants/viewModes';

export default {
  name: 'AppGearMenu',
  components: { AppButtonImage },
  data: () => ({
    opened: false,
    // не очень хорошо тут держать данные, но пока пусть лежат
    items: [
      {
        // Настройка фона
        id: 'change-background',
        iconPath: '/assets/gear_menu/back_btn_1.svg',
        activeIconPath: '/assets/gear_menu/back_btn_2.svg',
        title: '배경 설정',
        viewMode: VIEW_MODES.CHANGE_BACKGROUND,
      },
      {
        // Установка вазы
        id: 'setup-vase',
        iconPath: '/assets/gear_menu/base_btn_1.svg',
        activeIconPath: '/assets/gear_menu/base_btn_2.svg',
        title: '화병 설정',
        viewMode: VIEW_MODES.CHANGE_POT,
      },
      {
        // Поделиться
        id: 'share',
        iconPath: '/assets/gear_menu/share_btn_1.svg',
        activeIconPath: '/assets/gear_menu/share_btn_2.svg',
        title: '공유 하기',
      },
      {
        // Просмотр дополненной реальности
        id: 'ar-view',
        iconPath: '/assets/gear_menu/AR_btn_1.svg',
        activeIconPath: '/assets/gear_menu/AR_btn_2.svg',
        title: 'AR 보기',
      },
      {
        // О нас
        id: 'about-us',
        iconPath: '/assets/gear_menu/YG_btn_1.svg',
        activeIconPath: '/assets/gear_menu/YG_btn_2.svg',
        title: 'About Us',
      },
    ],
  }),
  methods: {
    ...mapMutations(['setActiveViewMode']),

    _onMenuItemClick(item) {
      this.opened = false;

      if (item.viewMode) {
        this.setActiveViewMode(item.viewMode);
      }

      if (item.id === 'ar-view') {
        this.$puzzlesBus.initAR();
      }

      if (item.id === 'share') {
        this._setFullscreenMode();
      }
    },

    _setFullscreenMode() {
      const $app = this.$root.$el;

      if ($app.requestFullscreen) {
        $app.requestFullscreen();
      } else if ($app.webkitRequestFullscreen) { /* Safari */
        $app.webkitRequestFullscreen();
      } else if ($app.msRequestFullscreen) { /* IE11 */
        $app.msRequestFullscreen();
      }
    },
  },
};
</script>

<style lang="scss">
.app-gear-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &__button {
    margin-bottom: 15px;
  }

  &__items {
    margin-right: 5px;
  }

  &__item {
    margin-bottom: 12px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }

  &__item-title {
    font-size: 15px;
    line-height: 12px;
    letter-spacing: -0.45px;
    font-weight: bold;
    margin-top: 6px;
    margin-right: 5px;
  }
}
</style>
