<template>
  <div class="app-change-background-view">
    <app-back-to-button @click="setActiveViewMode(VIEW_MODES.PREVIEW)" />
    <app-slider
      class="app-change-background-view__slider"
      :items="sliderItems"
      text-font-size="13px"
      @select="item => callSetBackground(item.id)"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import AppBackToButton from '@/components/BackToButton';
import * as VIEW_MODES from '../constants/viewModes';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import AppSlider from '@/components/Slider';

export default {
  name: 'AppChangeBackgroundView',
  components: {
    AppSlider,
    AppBackToButton,
    Swiper,
    SwiperSlide,
  },
  data: () => ({ VIEW_MODES }),
  computed: {
    ...mapState(['backgrounds']),

    sliderItems() {
      return this.backgrounds.map(background => ({
        id: background.id,
        previewImage: background.preview_path,
        title: background.name,
      }));
    },
  },
  methods: {
    ...mapMutations(['setActiveViewMode']),

    ...mapActions(['callSetBackground']),
  },
};
</script>

<style lang="scss">
.app-change-background-view {
  &__slider {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
</style>
