<template>
  <div class="app-slider">
    <app-button-image
      class="app-slider__prev-button"
      icon-path="/assets/front_1.svg"
      width="10px"
      height="17px"
      @click="() => $refs.swiper.swiperInstance.slidePrev()"
    />
    <app-button-image
      class="app-slider__next-button"
      icon-path="/assets/back_1.svg"
      width="10px"
      height="17px"
      @click="() => $refs.swiper.swiperInstance.slideNext()"
    />
    <swiper
      ref="swiper"
      class="swiper"
      :options="swiperOption"
    >
      <swiper-slide
        v-for="(item, itemIndex) in items"
        :key="itemIndex"
        style="width: 101px;"
      >
        <div
          class="app-slider__slide"
          @click="$emit('select', item)"
        >
          <div
            class="app-slider__slide-image"
            :style="_getBackgroundStyles(item)"
          />
          <div
            class="app-slider__slide-title"
            :style="{fontSize: textFontSize}"
          >{{ item.title }}
          </div>
          <div
            class="app-slider__slide-subtitle"
            :style="{fontSize: textFontSize}"
          >{{ item.subtitle }}
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import AppButtonImage from '@/components/ButtonImage';

export default {
  name: 'AppSlider',
  components: {
    AppButtonImage,
    Swiper,
    SwiperSlide,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    textFontSize: {
      type: String,
      default: '9px',
    },
  },
  computed: {
    swiperOption() {
      return {
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 6,
        initialSlide: Math.ceil(this.items.length / 2),
      };
    },
  },
  methods: {
    _getBackgroundStyles(item) {
      return { backgroundImage: `url(${item.previewImage})` };
    },
  },
};
</script>

<style lang="scss">
.app-slider {
  width: 100%;
  height: 130px;
  padding: 14px 23px 0 22px;
  box-sizing: border-box;
  background-color: #7f7f7f;

  &__slide {
    cursor: pointer;
  }

  &__slide-image {
    width: 101px;
    height: 76px;
    background: #fff;
    background-size: cover;
  }

  &__slide-title {
    color: #fff;
    text-align: center;
    letter-spacing: -0.3px;
  }

  &__slide-subtitle {
    color: #fff;
    text-align: center;
    letter-spacing: -0.3px;
  }

  &__prev-button {
    position: absolute;
    left: 8px;
    top: 45px
  }

  &__next-button {
    position: absolute;
    right: 7px;
    top: 45px
  }
}
</style>
