<template>
  <img
    class="app-logo"
    src="/assets/logo.png"
  />
</template>

<script>
export default {
  name: 'AppLogo',
};
</script>

<style lang="scss">
.app-logo {
  display: block;
  width: 113px;
  height: 57px;
}
</style>
