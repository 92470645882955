<template>
  <div class="app-preview-view">
    <app-logo class="app-preview-view__logo" />
    <app-gear-menu class="app-preview-view__gear-menu" />
    <div class="app-preview-view__bottom-panel">
      <div class="app-preview-view__order-button">
        <app-button-text
          width="196px"
          font-size="22px"
          :label="formattedOrderTotalPrice"
          theme="gray"
          :disabled="orderTotalPrice === 0"
          @click="setActiveViewMode(VIEW_MODES.ORDER)"
        />
        <app-button-text
          width="87px"
          font-size="22px"
          label="주문"
          theme="black"
          :disabled="orderTotalPrice === 0"
          @click="setActiveViewMode(VIEW_MODES.ORDER)"
        />
      </div>
      <app-button-image
        icon-path="/assets/scissors_btn_1.svg"
        width="52px"
        height="52px"
        @click="setActiveViewMode(VIEW_MODES.EDIT)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import AppLogo from '@/components/Logo';
import AppGearMenu from '@/components/GearMenu';
import AppButtonText from '@/components/ButtonText';
import AppButtonImage from '@/components/ButtonImage';
import * as VIEW_MODES from '../constants/viewModes';
import numberToPrice from '@/helpers/numberToPrice';

export default {
  name: 'AppPreviewView',
  components: {
    AppButtonImage,
    AppButtonText,
    AppGearMenu,
    AppLogo,
  },
  data: () => ({ VIEW_MODES }),
  computed: {
    ...mapState([]),
    ...mapGetters(['orderTotalPrice']),

    formattedOrderTotalPrice() {
      return numberToPrice(this.orderTotalPrice);
    },
  },
  methods: {
    ...mapMutations([
      'setActiveViewMode',
    ]),
    ...mapActions([]),
  },
};
</script>

<style lang="scss">
.app-preview-view {
  &__logo {
    position: absolute;
    top: 17px;
    left: 20px;
    z-index: 20;
  }

  &__gear-menu {
    position: absolute;
    right: 24px;
    top: 30px;
    z-index: 20;
  }

  &__bottom-panel {
    padding: 0 54px 0 35px;
    box-sizing: border-box;
    width: 360px;
    position: absolute;
    left: calc(50% - 180px);
    bottom: 31px;
    height: 52px;
    display: flex;
    justify-content: space-between;
    z-index: 20;
  }

  &__order-button {
    width: 196px;
    position: relative;

    & > button:nth-child(1) {
      justify-content: flex-start;
      padding: 0 0 0 22px;
    }

    & > button:nth-child(2) {
      position: absolute;
      right: 0;
      top: 0
    }
  }
}
</style>
