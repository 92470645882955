<template>
  <app-modal class="app-replace-flower-question-view">
    <app-back-to-button @click="setActiveViewMode(VIEW_MODES.EDIT)" />
    <div class="app-replace-flower-question-view__buttons">
      <app-button-text
        class="app-replace-flower-question-view__button"
        label="선택된 꽃만 바꾸기"
        theme="black"
        width="164px"
        font-size="18px"
        @click="setActiveViewMode(VIEW_MODES.REPLACE_FLOWER_SINGLE)"
      />
      <app-button-text
        class="app-replace-flower-question-view__button"
        label="모두 바꾸기"
        theme="black"
        width="164px"
        font-size="20px"
        @click="setActiveViewMode(VIEW_MODES.REPLACE_FLOWER_ALL)"
      />
    </div>
  </app-modal>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import AppFlowerRowItem from '@/components/FlowerRowItem';
import AppButtonImage from '@/components/ButtonImage';
import AppModal from '@/components/Modal';
import AppBackToButton from '@/components/BackToButton';
import AppButtonText from '@/components/ButtonText';
import * as VIEW_MODES from '../constants/viewModes';

export default {
  name: 'AppReplaceFlowerQuestionView',
  components: { AppButtonText, AppBackToButton, AppModal },
  data: () => ({ VIEW_MODES }),
  methods: {
    ...mapMutations(['setActiveViewMode']),
  },
};
</script>

<style lang="scss">
.app-replace-flower-question-view {
  &__buttons {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__button {
    margin-bottom: 26px;
  }
}
</style>
