<template>
  <app-modal class="app-order-view">
    <app-back-to-button @click="setActiveViewMode(VIEW_MODES.PREVIEW)" />
    <div class="app-order-view__flowers">
      <div class="app-order-view__flowers-wrapper">
        <vue-scroll
          ref="scroll"
          :ops="scrollOptions"
        >
          <app-flower-row-item
            v-for="flowerData in orderFlowersData"
            :key="flowerData.type.id"
            class="app-order-view__flower-row-item"
            :name="flowerData.type.name"
            :price="flowerData.totalPrice"
            :preview-image-path="flowerData.type.preview_path"
            :count="flowerData.count"
          >
            <app-button-image
              width="30px"
              height="31px"
              icon-path="/assets/delect_btn_1.svg"
              @click="() => _onClickDeleteFlower(flowerData)"
            />
          </app-flower-row-item>
        </vue-scroll>
      </div>
    </div>
    <div class="app-order-view__buttons">
      <app-button-text
        width="164px"
        font-size="22px"
        theme="gray"
        :label="formattedOrderTotalPrice"
        style="cursor: default"
      />
      <!-- заказ -->
      <app-button-text
        width="87px"
        font-size="22px"
        theme="gray"
        label="주문"
        :disabled="orderTotalPrice === 0"
        @click="setActiveViewMode(VIEW_MODES.ORDER_CONFIRM_VIEW)"
      />
    </div>
  </app-modal>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import AppFlowerRowItem from '@/components/FlowerRowItem';
import AppButtonImage from '@/components/ButtonImage';
import AppModal from '@/components/Modal';
import AppBackToButton from '@/components/BackToButton';
import * as VIEW_MODES from '../constants/viewModes';
import AppButtonText from '@/components/ButtonText';
import numberToPrice from '@/helpers/numberToPrice';

const MODES = {
  NEW: 'new',
  REPLACE_SINGLE: 'replace_single',
  REPLACE_ALL: 'replace_all',
};

export default {
  name: 'AppOrderView',
  components: { AppButtonText, AppBackToButton, AppModal, AppButtonImage, AppFlowerRowItem },
  data: () => ({
    scrollOptions: {
      vuescroll: { sizeStrategy: 'number' },
      bar: {
        keepShow: true,
        background: '#cacaca',
        size: '14px',
      },
      rail: {
        background: '#fff',
        opacity: 1,
        size: '14px',
      },
    },
    VIEW_MODES,
  }),
  mounted() {
    window.addEventListener('resize', this.updateScroll);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateScroll);
  },
  computed: {
    ...mapState([]),

    ...mapGetters([
      'orderFlowersData',
      'orderTotalPrice',
    ]),

    formattedOrderTotalPrice() {
      return numberToPrice(this.orderTotalPrice);
    },
  },
  methods: {
    ...mapMutations([
      'setActiveViewMode',
    ]),

    ...mapActions([
      'callDeleteById'
    ]),

    updateScroll() {
      this.$nextTick(() => this.$refs.scroll && this.$refs.scroll.refresh());
    },

    _onClickDeleteFlower(flowerData) {
      this.callDeleteById(flowerData.type.id);
    },
  },
};
</script>

<style lang="scss">
.app-order-view {
  opacity: 1;
  &__flowers {
    height: 100%;
    overflow: hidden;
    padding: 0 14px 0 45px;
    box-sizing: border-box;
    margin-top: 53px;
  }

  &__flowers-wrapper {
    height: 100%;
  }

  &__flower-row-item {
    margin-bottom: 10px;
    width: 269px;
  }

  &__buttons {
    width: 257px;
    display: flex;
    justify-content: space-between;
    margin: 20px auto 56px;
  }
}
</style>
