<template>
  <div class="app-flower-row-item">
    <img
      class="app-flower-row-item__preview"
      :src="previewImagePath"
      alt="preview"
    >
    <div class="app-flower-row-item__content">
      <div class="app-flower-row-item__name">{{ formattedName }}</div>
      <div class="app-flower-row-item__price">{{ formattedPrice }}</div>
    </div>
    <div class="app-flower-row-item__action">
      <slot />
    </div>
  </div>
</template>

<script>
import numberToPrice from '../helpers/numberToPrice';

export default {
  name: 'AppFlowerRowItem',
  props: {
    previewImagePath: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
    count: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    formattedPrice() {
      return numberToPrice(this.price);
    },

    formattedName() {
      return this.count === 1
        ? this.name
        : `${this.name} x${this.count}`
    }
  },
};
</script>

<style lang="scss">
.app-flower-row-item {
  width: 100%;
  height: 71px;
  display: flex;
  padding: 8px;
  align-items: center;
  background-color: #fff;
  border-radius: 48px;
  box-sizing: border-box;

  &__preview {
    flex: 0 0 auto;
    width: 50px;
    height: 50px;
    margin-right: 18px;
    border-radius: 48px;
    display: block;
  }

  &__content {
    flex: 1 1 auto;
  }

  &__name {
    font-size: 17px;
    letter-spacing: -0.51px;
    text-align: left;
  }

  &__price {
    font-size: 13px;
    letter-spacing: -0.39px;
    text-align: left;
  }

  &__action {
    flex: 0 0 auto;
    margin-right: 8px;
    // 30x30
  }
}
</style>
