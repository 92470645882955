<template>
  <div class="app-edit-view">
    <app-back-to-button @click="handleBackClick" />
    <app-button-image
      class="app-edit-view__undo-button"
      width="18px"
      height="14px"
      icon-path="/assets/undo_btn_1.svg"
      @click="callSceneUndo"
    />
    <app-button-image
      class="app-edit-view__redo-button"
      width="18px"
      height="14px"
      icon-path="/assets/redo_btn_1.svg"
      @click="callSceneRedo"
    />

    <div class="app-edit-view__bottom-panel">
      <div class="app-edit-view__bottom-panel-left">
        <app-button-image
          icon-path="/assets/change_btn_1.svg"
          width="52px"
          height="52px"
          :disabled="!activeFlowerTypeId"
          @click="setActiveViewMode(VIEW_MODES.REPLACE_FLOWER_QUESTION)"
        />
        <app-button-image
          icon-path="/assets/copy_btn_1.svg"
          width="52px"
          height="52px"
          :disabled="!activeFlowerTypeId"
          @click="callSceneCloneActiveFlower"
        />
        <app-button-image
          icon-path="/assets/delect_btn_1.svg"
          width="52px"
          height="52px"
          :disabled="!activeFlowerTypeId"
          @click="callSceneDeleteActiveFlower"
        />
        <app-button-image
          icon-path="/assets/cut_btn_1.svg"
          width="52px"
          height="52px"
          :disabled="!activeFlowerTypeId"
          @click="setActiveViewMode(VIEW_MODES.CUT)"
        />
      </div>
      <app-button-image
        icon-path="/assets/blue_plus_button.svg"
        width="52px"
        height="52px"
        @click="setActiveViewMode(VIEW_MODES.ADD_FLOWER)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import AppButtonImage from '@/components/ButtonImage';
import AppBackToButton from '@/components/BackToButton';
import * as VIEW_MODES from '../constants/viewModes';

export default {
  name: 'AppEditView',
  components: {
    AppBackToButton,
    AppButtonImage,
  },
  data: () => ({ VIEW_MODES }),
  computed: {
    ...mapState(['activeFlowerTypeId']),
  },
  methods: {
    ...mapMutations(['setActiveViewMode']),

    ...mapActions([
      'callSceneRedo',
      'callSceneUndo',
      'callSceneCloneActiveFlower',
      'callSceneDeleteActiveFlower',
      'callSceneAddFlower',
      'callDeleteById',
      'callChangeFlower',
      'callChangeFlowersById',
    ]),

    handleBackClick() {
      this.$puzzlesBus.unActive();
      this.setActiveViewMode(VIEW_MODES.PREVIEW)
    }
  },
};
</script>

<style lang="scss">
.app-edit-view {
  &__undo-button {
    position: absolute;
    top: 31px;
    right: 51px;
    z-index: 20;
  }

  &__redo-button {
    position: absolute;
    top: 31px;
    right: 23px;
    z-index: 20;
  }

  &__bottom-panel {
    padding: 0 25px 0 43px;
    box-sizing: border-box;
    width: 360px;
    position: absolute;
    left: calc(50% - 180px);
    bottom: 31px;
    height: 52px;
    display: flex;
    justify-content: space-between;
    z-index: 20;
  }

  &__bottom-panel-left {
    display: flex;

    & > * {
      margin-right: 5px;
    }
  }
}
</style>
