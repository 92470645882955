<template>
  <div class="app-v3d" :id="containerId">

    <div id="preloader_screen" class="preloader-screen">
      <div class="section">
        <div class="preloader-scooter-background">
          <div id="loading_text" class="loading-text">0%</div>
          <hr id="loading_bar" class="l_bar" />
        </div>
      </div>
    </div>

<!--    <div class="zero-group">-->
<!--      <a class="btn" id="hd_btn"><img class="icon" src="v3dApp/media/icons/hd.png" alt="" /></a>-->
<!--    </div>-->

<!--    <div class="one-group">-->
<!--      <a class="btn" id="fullscreen_button"><img class="icon" src="v3dApp/media/icons/full.png" alt="" /></a>-->
<!--      <a class="btn" id="ss_button"><img class="icon" src="v3dApp/media/icons/screen.png" alt="" /></a>-->
<!--      <a class="btn" ></a>-->
<!--      <a class="btn" id="plus_button"><img class="icon" src="v3dApp/media/icons/plus.png" alt="" /></a>-->
<!--      <a class="btn" id="minus_button"><img class="icon" src="v3dApp/media/icons/minus.png" alt="" /></a>-->
<!--    </div>-->



  </div>
</template>

<script>
import * as v3dAppAPI from '@/services/V3DApp';

export default {
  name: 'V3DApp',

  data() {
    return {
      containerId: v3dAppAPI.CONTAINER_ID,
    }
  },

  app: null,

  mounted() {
    v3dAppAPI.createApp(() => {
      setTimeout(() => {
        this.$puzzlesBus.init();
      }, 200);
    }).then(app => {
      //setTimeout(() => { // для слабой машины
      //  app.renderer.setPixelRatio(0.2);
      //}, 1000)

      // $options это штука, которая используется для конструирования vnode компонента, в ней ничего хранить не нужно :)
      // если не хочется пихать в реактивное свойство $data app, то положи его просто в this :)
      // это шаблон от verge3d, я его старался не трогать
      this.$options.app = app;
    });
  },

  beforeDestroy() {
    if (this.$options.app) {
      this.$options.app.dispose();
      this.$options.app = null;
    }
  },
}
</script>

<style lang="scss">
@import '../styles/v3d.scss';

.app-v3d {
  background: #FFFFFF;
}

</style>
