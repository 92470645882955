<template>
  <button
    class="app-button-image"
    :style="styles"
    :disabled="disabled"
    @click="$emit('click')"
  />
</template>

<script>
export default {
  name: 'AppButtonImage',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    iconPath: {
      type: String,
      required: true,
    },
    activeIconPath: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    styles() {
      const backgroundUrl = this.active ? this.activeIconPath : this.iconPath;

      return {
        backgroundImage: `url(${backgroundUrl})`,
        backgroundSize: this.size,
        width: this.width,
        height: this.height,
      };
    },
  },
};
</script>

<style lang="scss">
.app-button-image {
  transition: background-image 0.2s;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  background-size: cover;
  outline: none;

  &:disabled {
    cursor: not-allowed;
  }
}
</style>
