<template>
  <div class="app-main-view">

    <div v-if="isSceneLoaded" class="app-main-view__loader">
      <div class="app-main-view__loader-wrapper">
        <div class="app-main-view__loader-container">
          <img class="app-main-view__loader-img" src="v3dApp/media/loader.gif">
        </div>
      </div>
    </div>

    <transition name="fade">
      <app-preview-view
          class="app-main-view__sub-view"
          v-if="checkViewModeIsActive(VIEW_MODES.PREVIEW)"
      />
      <app-edit-view
          class="app-main-view__sub-view"
          v-if="checkViewModeIsActive(VIEW_MODES.EDIT)"
      />
      <app-cut-view
          class="app-main-view__sub-view"
          v-if="checkViewModeIsActive(VIEW_MODES.CUT)"
      />
      <app-add-flower-view
          class="app-main-view__sub-view"
          v-if="checkViewModeIsActive(VIEW_MODES.ADD_FLOWER)"
          mode="new"
      />
      <app-replace-flower-question-view
          class="app-main-view__sub-view"
          v-if="checkViewModeIsActive(VIEW_MODES.REPLACE_FLOWER_QUESTION)"
      />
      <app-add-flower-view
          class="app-main-view__sub-view"
          v-if="checkViewModeIsActive(VIEW_MODES.REPLACE_FLOWER_ALL)"
          mode="replace_all"
      />
      <app-add-flower-view
          class="app-main-view__sub-view"
          v-if="checkViewModeIsActive(VIEW_MODES.REPLACE_FLOWER_SINGLE)"
          mode="replace_single"
      />
      <app-change-background-view
          class="app-main-view__sub-view"
          v-if="checkViewModeIsActive(VIEW_MODES.CHANGE_BACKGROUND)"
      />
      <app-change-pot-view
          class="app-main-view__sub-view"
          v-if="checkViewModeIsActive(VIEW_MODES.CHANGE_POT)"
      />
      <app-order-view
          class="app-main-view__sub-view app-main-view__sub-view--name_order"
          v-if="checkViewModeIsActive(VIEW_MODES.ORDER)"
      />
      <app-order-confirm-view
          class="app-main-view__sub-view app-main-view__sub-view--name_order-confirm-view"
          v-if="checkViewModeIsActive(VIEW_MODES.ORDER_CONFIRM_VIEW)"
      />
    </transition>
    <v3-d-app
        class="app-main-view__canvas"
        @loader="handleAppLoaded"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import * as VIEW_MODES from '../constants/viewModes';
import V3DApp from '../components/V3DApp';
import AppPreviewView from '@/views/PreviewView';
import AppEditView from '@/views/EditView';
import AppCutView from '@/views/CutView';
import AppAddFlowerView from '@/views/AddFlowerView';
import AppReplaceFlowerQuestionView from '@/views/ReplaceFlowerQuestionView';
import AppChangeBackgroundView from '@/views/ChangeBackgroundView';
import AppChangePotView from '@/views/ChangePotView';
import AppOrderView from '@/views/OrderView';
import AppOrderConfirmView from '@/views/OrderConfirmView';

export default {
  name: 'AppMainView',
  components: {
    AppOrderConfirmView,
    AppOrderView,
    AppChangePotView,
    AppChangeBackgroundView,
    AppReplaceFlowerQuestionView,
    AppAddFlowerView,
    AppCutView,
    AppEditView,
    AppPreviewView,
    V3DApp,
  },
  data: () => ({
    activeGearMenuId: undefined,
    VIEW_MODES,
  }),
  mounted() {
    this.fetchFlowersTypes();
    this.fetchBackgrounds();
    this.fetchPots();
  },
  computed: {
    ...mapState(['isSceneLoaded']),
    ...mapGetters([
      'checkViewModeIsActive',
    ]),
  },
  methods: {
    ...mapActions([
      'fetchFlowersTypes',
      'fetchBackgrounds',
      'fetchPots',
    ]),

    handleAppLoaded() {
      this.$puzzlesBus.init();
    },
  },
};
</script>

<style lang="scss">
.app-main-view {
  width: 100%;
  height: 100%;
  position: relative;

  &__loader {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 99999;
  }

  &__loader-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
  }

  &__loader-container {
    position: absolute;
    display: block;
    overflow: hidden;
    width: 100%;
    height: 10%;
    bottom: 0;
    margin-right: auto;
    margin-left: auto;
    left: 0;
    right: 0;
    z-index: 999;
    top: 40%;
  }

  &__loader-img {
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    margin: auto;
  }

  &__sub-view {
    pointer-events: none;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 20;

    & > * {
      pointer-events: auto;
    }
  }

  &__canvas {
    z-index: 10;
    position: relative;
  }
}
</style>
