import Vue from 'vue';
import { Slider } from 'element-ui';
import App from './App.vue';
import Vuex from 'vuex';
import EventsObserver from 'events-observer';
import PuzzlesBus from '@/services/PuzzlesBus';
import store from './store';
import './styles/main.scss';
import Vuescroll from 'vuescroll';

window.UIBus = new EventsObserver();
//Vue.prototype.$bus = window.UIBus

const puzzlesBus = new PuzzlesBus({ bus: window.UIBus, store: store });
window.puzzlesBus = puzzlesBus;

Vue.prototype.$puzzlesBus = puzzlesBus;

Vue.use(Vuex);
Vue.use(Slider);
Vue.config.productionTip = false;
Vue.component('vue-scroll', Vuescroll);

new Vue({
  render: h => h(App),
  store,
}).$mount('#app');
