<template>
  <button
    class="app-button-text"
    :class="classes"
    :style="styles"
    :disabled="disabled"
    @click="$emit('click')"
  >
    {{ label }}
  </button>
</template>

<script>
export default {
  name: 'AppButtonText',
  props: {
    width: {
      type: [String, Number],
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    fontSize: {
      type: String,
      required: true,
    },
    theme: {
      type: String,
      required: true,
      validator(value) {
        return ['gray', 'black'].includes(value);
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    styles() {
      return {
        width: this.width,
        fontSize: this.fontSize,
      };
    },
    classes() {
      return [
        `app-button-text--theme_${this.theme}`,
      ];
    },
  },
};
</script>

<style lang="scss">
.app-button-text {
  height: 52px;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 26px;
  cursor: pointer;
  overflow: hidden;
  text-align: center;
  letter-spacing: -0.5px;
  color: #fff;
  border: none;
  outline: none;

  &--theme {
    &_gray {
      background-color: #6f6f6f;
    }

    &_black {
      background-color: #000000f7;
    }

    &_red {
      background-color: #ff3e3e;
    }
  }

  &:disabled {
    cursor: not-allowed;
  }
}
</style>
