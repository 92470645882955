<template>
  <app-modal class="app-order-confirm-view">
    <app-back-to-button @click="setActiveViewMode(isConfirmed ? VIEW_MODES.PREVIEW : VIEW_MODES.ORDER)" />
    <div
      class="app-order-confirm-view__buttons"
      :key="isConfirmed"
    >
      <template v-if="isConfirmed">
        <app-button-text
          class="app-order-confirm-view__button"
          label="Thank you for Order!"
          theme="black"
          width="208px"
          font-size="17px"
          @click="setActiveViewMode(VIEW_MODES.PREVIEW)"
        />
      </template>
      <template v-else>
        <!-- Получайте только ингредиенты -->
        <app-button-text
          class="app-order-confirm-view__button"
          label="재료만 받기"
          theme="black"
          width="164px"
          font-size="20px"
          @click="isConfirmed = true"
        />
        <!-- Заказать букет -->
        <app-button-text
          class="app-order-confirm-view__button"
          label="꽃다발 주문"
          theme="black"
          width="164px"
          font-size="20px"
          @click="isConfirmed = true"
        />
        <!-- Подарок -->
        <app-button-text
          class="app-order-confirm-view__button"
          label="선물하기"
          theme="black"
          width="164px"
          font-size="20px"
          @click="isConfirmed = true"
        />
      </template>
    </div>
  </app-modal>
</template>

<script>
import { mapMutations } from 'vuex';
import AppModal from '@/components/Modal';
import AppBackToButton from '@/components/BackToButton';
import AppButtonText from '@/components/ButtonText';
import * as VIEW_MODES from '../constants/viewModes';

export default {
  name: 'AppOrderConfirmView',
  components: { AppButtonText, AppBackToButton, AppModal },
  data: () => ({
    VIEW_MODES,
    isConfirmed: false,
  }),
  methods: {
    ...mapMutations(['setActiveViewMode']),
  },
};
</script>

<style lang="scss">
.app-order-confirm-view {
  &__buttons {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__button + .app-order-confirm-view__button {
    margin-top: 26px;
  }
}
</style>
